import React from 'react';

import clsx from 'clsx';

import { fade, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Typography from '@material-ui/core/Typography';

import PartyIcon from '../icons/Party';
import EventIcon from '../icons/Event';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  selectAll: {
    margin: 0,
    position: 'absolute',
    right: '-5px',
    top: '3px',
    '& .MuiFormControlLabel-label': {
      ...theme.typography.caption,
      color: theme.palette.text.secondary,
    },
    '& .MuiIconButton-root': {
      padding: '5px',
    },
    '& .MuiSvgIcon-root': {
      height: '14px',
      width: '14px',
    },
  },
  checkbox: {
    '&.MuiFormGroup-root': {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: theme.spacing(2),
      overflow: 'auto',
      width: '100%',
    },
    '& .MuiFormControlLabel-root': {
      alignItems: 'center',
      border: `2px solid ${ theme.palette.primary.light }`,
      borderRadius: '12px',
      display: 'flex',
      height: '72px',
      justifyContent: 'center',
      marginBottom: theme.spacing(1.5),
      marginLeft: 0,
      marginRight: '12px',
      position: 'relative',
      textAlign: 'center',
      width: '68px',
      '&:last-child': {
        marginRight: 0,
      },
    },
    '& .MuiButtonBase-root': {
      color: theme.palette.secondary.dark,
      padding: 0,
      position: 'absolute',
      right: '4px',
      top: '4px',
      zIndex: 1,
      '&:not(.Mui-checked)': {
        display: 'none',
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
    },
    '& .MuiFormControlLabel-label': {
      '& span': {
        color: theme.palette.primary.main,
        fontSize: '12px',
        lineHeight: '12px',
        position: 'relative',
        zIndex: 1,
      },
      '& span:nth-child(1)': {
        display: 'block',
        fontSize: '34px',
        lineHeight: '40px',
        top: '10px',
      },
      '&.Mui-disabled': {
        '& span': {
          color: theme.palette.text.disabled,
        },
      },
    },
  },
  checked: {
    '&.MuiFormControlLabel-root': {
      backgroundColor: theme.palette.secondary.overlay,
      borderColor: theme.palette.secondary.main,
    },
  },
  edwardsParty: {
    '&.MuiFormControlLabel-root': {
      '& span.MuiTypography-root': {
        alignItems: 'flex-end',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        marginBottom: '13px',
        width: '100%',
        '& .MuiSvgIcon-root': {
          color: fade(theme.palette.secondary.main, .6),
          height: '40px',
          left: 0,
          position: 'absolute',
          top: '5px',
          width: '100%',
        },
      },
    },
  },
}));

const FormikDays = ({
  field,
  form: { setFieldTouched, touched, errors, values, setFieldValue },
  name,
  options,
  ...props
}) => {
  const classes = useStyles();
  const fieldName = name || field.name;

  const [selectAll, setSelectAll] = React.useState(false);

  const handleSelectAllChange = () => {
    const newValue = values.days.map(day => {
      return {
        ...day,
        checked: !selectAll
      }
    });
    setFieldValue('days', newValue, false);
    setFieldTouched('days', false);
    setSelectAll(!selectAll);
  };

  const IS_EDWARDS = localStorage.getItem('schema') === 'srva' && localStorage.getItem('clientId') === '6';
  const EDWARDS_PARTY_DATE = IS_EDWARDS && '2024-12-07';
  const EDWARDS_EVENT_DATE = IS_EDWARDS && '2025-01-11';

  const t = useTranslation();

  return (
    <div { ...props }>
      <FormControl component="fieldset" error={ !!touched[fieldName] && !!errors[fieldName] } className={ classes.root }>
        <Typography variant="overline" component="p">{ props.label }</Typography>
        <FormControlLabel
          className={ classes.selectAll }
          control={ <Checkbox
                      color="primary"
                      checked={ selectAll }
                      disabled={ props.disabled }
                      onChange={ handleSelectAllChange }
                    />
          }
          label={ t('home.days.selectAll') }
          labelPlacement="start"
        />
        <FormGroup { ...field } name={ fieldName } className={ classes.checkbox }>
          { options.map(option => (
            <FormControlLabel
              key={ option.dateFormat.getTime() }
              className={clsx({ [classes.checked]: option.checked, [classes.edwardsParty]: option.day === EDWARDS_PARTY_DATE || option.day === EDWARDS_EVENT_DATE })}
              control={ <Checkbox
                          name={ `${ option.dateFormat.getTime() }` }
                          checked={ option.checked }
                          disabled={ props.disabled }
                          onChange={ () => {
                            option.checked = !option.checked;
                            setFieldTouched([fieldName], true);
                            const daysChecked = values.days.map(day => day.checked);
                            setSelectAll(daysChecked.every(Boolean));
                          } }
                          icon={ <RadioButtonUncheckedIcon /> }
                          checkedIcon={ <CheckCircleIcon /> }
                        />
              }
              label={
                <React.Fragment>
                  { option.day === EDWARDS_PARTY_DATE || option.day === EDWARDS_EVENT_DATE ? (
                    <React.Fragment>
                      <span></span>
                      <span>{ option.dateFormat.getDate() },&nbsp;</span>
                      <span>{ t(`global.months.${ option.dateFormat.getMonth() }`) }</span>
                      { option.day === EDWARDS_PARTY_DATE ? <PartyIcon /> : <EventIcon /> }
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span>{ option.dateFormat.getDate() }</span>
                      <span>{ t(`global.days.${ option.dateFormat.getDay() }`).substring(0, 3) },&nbsp;</span>
                      <span>{ t(`global.months.${ option.dateFormat.getMonth() }`) }</span>
                    </React.Fragment>
                  ) }
                </React.Fragment>
              }
            />
          )) }
        </FormGroup>

        { touched[fieldName] && errors[fieldName] && (
          <FormHelperText>{ errors[fieldName] }</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default FormikDays;
