import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';


import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

import { Formik, Form, Field } from 'formik';

import FormikRadioGroup from '../components/FormikRadioGroup';
import FormikAutocomplete from '../components/FormikAutocomplete';
import FormikDays from '../components/FormikDays';

import { transformDate, formatTime, sortByTime, DEFAULT_SNACKBAR, handleEndpointErrors, SNACKBAR_TIME } from '../shared/utilities';
import { useDebounce } from '../shared/hooks/useDebounce';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    '& .MuiSkeleton-text': {
      transform: 'none',
    },
  },
  input: {
    display: 'block',
    marginBottom: theme.spacing(3),
  },
  btnSubmit: {
    display: 'flex',
    margin: `${ theme.spacing(3) }px auto`,
    minWidth: '200px',
    borderRadius: '24px',
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const [noDaysAvailable, setNoDaysAvailable] = React.useState(false);

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const routes = useStoreState(state => state.routes.tripsRoutes);
  const routesLoading = useStoreState(state => state.routes.tripsRoutesLoading);
  const routesSoftLoading = useStoreState(state => state.routes.tripsRoutesSoftLoading);
  // const routesError = useStoreState(state => state.routes.tripsRoutesError);
  const getRoutes = useStoreActions(actions => actions.routes.getTripsRoutes);

  const routePlaces = useStoreState(state => state.places.routePlaces);
  const getRoutePlaces = useStoreActions(actions => actions.places.getRoutePlaces);
  const userPlaces = useStoreState(state => state.places.userPlaces);
  const getUserPlaces = useStoreActions(actions => actions.places.getUserPlaces);
  const routeSchedules = useStoreState(state => state.schedules.routeSchedules);
  const routeSchedulesLoading = useStoreState(state => state.schedules.routeSchedulesLoading);
  const getRouteSchedules = useStoreActions(actions => actions.schedules.getTripsRoutesSchedules);

  const postReservation = useStoreActions(actions => actions.reservations.postReservation);

  const [hasDirectionChanged, setHasDirectionChanged] = React.useState(false);
  const [initialRoutesLength, setInitialRoutesLength] = React.useState(0);
  const [routeName, setRouteName] = React.useState(null);
  const debouncedRouteName = useDebounce(routeName, 500);

  const onGetRoutes = (payload = { loading: false, direction: '' }) => {
    getRoutes(payload).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.tripsRoutesLoading && !routesState.tripsRoutesError) {
        // console.log(routesState.items);
        setSnackbar(DEFAULT_SNACKBAR);
      } else {
        handleEndpointErrors({ ...routesState, loading: routesState.tripsRoutesLoading, error: routesState.tripsRoutesError }, props, setSnackbar, t);
      }
    });
  };

  const onGetRoutePlaces = (payload = '') => {
    getRoutePlaces(payload).then(() => {
      const placesState = store.getState().places;
      if (!placesState.routePlacesLoading && !placesState.routePlacesError) {
        // console.log(placesState.items);
      } else {
        handleEndpointErrors({ ...placesState, loading: placesState.routePlacesLoading, error: placesState.routePlacesError }, props, setSnackbar, t);
      }
    });
  };

  const onGetUserPlaces = () => {
    getUserPlaces().then(() => {
      const placesState = store.getState().places;
      if (!placesState.userPlacesLoading && !placesState.userPlacesError) {
        // console.log(placesState.items);
      } else {
        handleEndpointErrors({ ...placesState, loading: placesState.userPlacesLoading, error: placesState.userPlacesError }, props, setSnackbar, t);
      }
    });
  };

  const onGetRouteSchedules = (payload = '') => {
    getRouteSchedules(payload).then(() => {
      const schedulesState = store.getState().schedules;
      if (!schedulesState.routeSchedulesLoading && !schedulesState.routeSchedulesError) {
        // console.log(schedulesState.routeSchedules);
      } else {
        handleEndpointErrors({ ...schedulesState, loading: schedulesState.routeSchedulesLoading, error: schedulesState.routeSchedulesError }, props, setSnackbar, t);
      }
    });
  };

  React.useEffect(() => {
    onGetRoutes({ loading: debouncedRouteName === null, direction: formRef.current.values.direction, textSearch: debouncedRouteName });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedRouteName]);

  React.useEffect(() => {
    if (debouncedRouteName === null || hasDirectionChanged) {
      setHasDirectionChanged(false);
      setInitialRoutesLength(routes.length);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);

  React.useEffect(() => {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.INFO, severity: 'info', message: t('home.routes.loading')  });
    onGetUserPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDirectionChange = (values) => {
    setHasDirectionChanged(true);
    onGetRoutes({ direction: values.direction === 'in' ? 'out' : 'in' });
  };

  // eslint-disable-next-line array-callback-return
  const updateFields = (fields, setFieldValue, setFieldTouched, values) => fields.map(field => {
    if (field === 'days') {
      const days = routeSchedules.find(schedule => schedule.routeScheduleId === values.time.routeScheduleId).days;
      setFieldValue(`${ field }`, getDays(days || []), false);
      setNoDaysAvailable(days ? false : true);
    } else {
      field === 'to' && onDirectionChange(values);
      setFieldValue(`${ field }`, '', false);
      setNoDaysAvailable(false);
    }

    setFieldTouched(`${ field }`, false);
  });

  const getDays = days => days.map((day) => ({
    day: transformDate(day).split('/').join('-'),
    dateFormat: new Date(transformDate(day)),
    checked: false
  }));

  const getPlaceOptions = (values) => {
    const places = values?.to?.type?.toLowerCase() === 'dtd' ? userPlaces : routePlaces || [];
    if (places.every(place => place.arrivalTime)) {
      return sortByTime(places, 'arrivalTime');
    }
    return places;
  };

  const getPlaceLabel = (direction, routeType) => {
    switch(routeType) {
      case 'DTD':
        if (direction === 'in') {
          return t('home.place.labelInDTD');
        } else {
          return t('home.place.labelOutDTD');
        }
      default:
        return t('home.place.labelPeripheral');
    }
  };

  const t = useTranslation();

  storeMenuTitle(t('home.pageTitle'));

  const directionOptions = [
    { label: t('global.direction.in'), value: 'in' },
    { label: t('global.direction.out'), value: 'out' }
  ];

  const formRef = React.useRef();

  return (
    <Formik
      innerRef={ formRef }
      initialValues={{
        direction: 'in',
        to: '',
        time: '',
        place: '',
        days: []
      }}
      validate={values => {
        const errors = {};
        if ( !values.to ) {
          errors.to = t('home.to.error');
        }
        if ( !values.place ) {
          errors.place = t('home.place.error');
        }
        if ( !values.time ) {
          errors.time = t(`home.time.${ values.direction }.error`);
        }
        if ( !values.days.filter(day => day.checked).length ) {
          errors.days = t('home.days.error');
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        const data = {
          routeId: values.to.id,
          passengerId: localStorage.getItem('id'),
          routeScheduleId: values.time.routeScheduleId,
          dates: values.days.filter(day => day.checked).map(day => day.day),
          placeId: values.place.id
        };
        postReservation(data).then(() => {
          const reservationsState = store.getState().reservations;
          if (!reservationsState.loading && !reservationsState.error) {
            setSubmitting(false);
            setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('reservations.snackbar.created') });
            props.history.push({ pathname: '/reservations' });
          } else {
            setSubmitting(false);
            if (reservationsState.error.status === 460) {
              handleEndpointErrors(reservationsState, props, setSnackbar, t, 'create');
            } else {
              handleEndpointErrors(reservationsState, props, setSnackbar, t, t('reservations.errorEntity'));
            }
          }
        });
      }}
    >
      {({ setFieldValue, setFieldTouched, values, submitForm, isSubmitting }) => (
        <Form className={ classes.root }>

          <Field
            name="direction"
            component={ FormikRadioGroup }
            className={ classes.input }
            label={ t('global.direction.label') }
            options={ directionOptions }
            disabled={ routesLoading || isSubmitting }
            onChange={() => {
              updateFields(['to', 'time', 'place'], setFieldValue, setFieldTouched, values);
            }}
          />

          { routesLoading ?
            <Skeleton className={ classes.input } variant="text" height={ 56 } />
          :
            <Field
              component={ FormikAutocomplete }
              name="to"
              className={ classes.input }
              options={ routes ? routes.filter(route => route?.direction?.toLowerCase() === values?.direction) : [] }
              // groupBy={ option => option.type }
              getOptionLabel={ option => option ? option.name : '' }
              textFieldProps={{
                label: t('home.to.label'),
                readOnly: initialRoutesLength >= 15 ? false : true,
                fullWidth: true,
                variant: 'outlined',
                icon: <DirectionsBusIcon />,
                onChange: event => {
                  setRouteName( event.target.value );
                }
              }}
              onFocus={() => {
                setRouteName('');
              }}
              noOptionsText={ routesSoftLoading ? t('global.noOptionsLoading') : undefined }
              onChange={() => {
                setTimeout(() => {
                  const formValues = formRef?.current?.values;
                  if (formValues?.to?.id) {
                    onGetRouteSchedules(formValues.to.id);
                    if (!(formValues.to.type?.toLowerCase() === 'dtd')) {
                      onGetRoutePlaces(formValues.to.id);
                    }
                  }
                });
                updateFields(['time', 'place'], setFieldValue, setFieldTouched, values);
              }}
            />
          }

          { routesLoading ?
            <Skeleton className={ classes.input } variant="text" height={ 56 } />
          :
            <Field
              component={ FormikAutocomplete }
              name="time"
              className={ classes.input }
              options={ values.to ? routeSchedules.filter(schedule => schedule.routeId === values.to.id) || [] : [] }
              getOptionLabel={ option => option ? `${ values.direction === 'in' ? formatTime(option.arrivalTime) : formatTime(option.departureTime) }` : '' }
              textFieldProps={{ label: t(`home.time.${ values.direction }.label`), fullWidth: true, variant: 'outlined', icon: <ScheduleIcon /> }}
              noOptionsText={ routeSchedulesLoading ? t('global.noOptionsLoading') : undefined }
              disabled={ isSubmitting || !values.to }
              onChange={() => {
                updateFields(['place'], setFieldValue, setFieldTouched, values);
              }}
            />
          }

          { routesLoading ?
            <Skeleton className={ classes.input } variant="text" height={ 56 } />
          :
            <Field
              component={ FormikAutocomplete }
              name="place"
              className={ classes.input }
              options={ values.to ? getPlaceOptions(values) || [] : [] }
              // groupBy={ option => option.type }
              getOptionLabel={ option => option ? `${ option.county.name } - ${ option.description }${ option.arrivalTime ? ` - ${ formatTime(option.arrivalTime) }` : '' }` : '' }
              textFieldProps={{ label: getPlaceLabel(values.direction, values.to?.type), fullWidth: true, variant: 'outlined', icon: <LocationOnIcon /> }}
              disabled={ isSubmitting || !values.time }
              onChange={() => {
                updateFields(['days'], setFieldValue, setFieldTouched, values);
              }}
            />
          }

          { values.place && (
            noDaysAvailable ? <Typography variant="body2">{ t('home.days.noDaysAvailable') }</Typography> : (
              <Field
                name="days"
                component={ FormikDays }
                className={ classes.input }
                label={ t('home.days.label') }
                options={ values.days }
                disabled={ isSubmitting }
              />
            )
          ) }

          <Button
            className={ classes.btnSubmit }
            type="submit"
            disabled={ routesLoading || isSubmitting || noDaysAvailable }
            onClick={ submitForm }
            variant="contained"
            color="primary"
          >{ t('home.button') }</Button>
        </Form>
      )}
    </Formik>
  );
}

Home.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default withRouter(Home);
