import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { fade, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import DescriptionIcon from '@material-ui/icons/Description';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Tag from '../components/Tag';
import Status from '../components/Status';
import Route from '../components/Route';

import { useTranslation } from 'react-multi-lang';


const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '12px',
    boxShadow: '0px 16px 80px 0px rgba(0, 0, 0, 0.16)',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    '& .MuiCardContent-root': {
      position: 'relative',
    },
    '& .MuiCardActions-root': {
      justifyContent: 'space-between',
      padding: `0 ${ theme.spacing(2) }px ${ theme.spacing(1) }px`,
    },
    '& .MuiIconButton-root': {
      marginLeft: theme.spacing(-1.5),
    },
    '& .MuiButton-text': {
      marginLeft: theme.spacing(-1),
    },
    '& .MuiIconButton-root + .MuiButton-text': {
      marginLeft: theme.spacing(1),
    },
  },
  routeName: {
    paddingRight: '125px',
  },
  rightContent: {
    position: 'absolute',
    right: '20px',
    top: '15px',
  },
  chip: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(.5),
  },
  note: {
    alignItems: 'center',
    border: props => `1px solid ${ theme.palette.status[props.status] ? fade(theme.palette.status[props.status], 0.24) : fade(theme.palette.text.secondary, 0.24) }`,
    borderRadius: theme.spacing(.5),
    display: 'flex',
    marginTop: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    '& .MuiSvgIcon-root': {
      color: props => theme.palette.status[props.status] ? theme.palette.status[props.status] : theme.palette.text.secondary,
      marginRight: theme.spacing(1.5),
    },
  },
  tripInfo: {
    borderTop: `1px dashed ${ theme.palette.divider }`,
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 2, 0),
    position: 'relative',
    width: `calc(100% + ${ theme.spacing(4) }px)`,
    '& .status': {
      position: 'absolute',
      right: '20px',
      top: '20px',
    },
    '& .passenger': {
      marginTop: theme.spacing(2),
    },
    '& .MuiGrid-container': {
      marginTop: theme.spacing(1),
    },
    '& .MuiTypography-overline': {
      lineHeight: '20px',
    },
    '&::before, &::after': {
      backgroundColor: theme.palette.type === 'light' ? '#ececec' : '#353641',
      borderRadius: '50%',
      content: '""',
      height: theme.spacing(3),
      position: 'absolute',
      top: theme.spacing(-1.5),
      width: theme.spacing(3),
      zIndex: 1,
    },
    '&::before': {
      left: theme.spacing(-1.5),
    },
    '&::after': {
      right: theme.spacing(-1.5),
    },
  },
  cardBack: {
    paddingBottom: '62px',
    '& .MuiTypography-subtitle1': {
      paddingRight: '100px',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.light,
      fontSize: '40px',
      position: 'absolute',
      right: '22px',
      top: '25px',
    },
  },
  backPrimaryBtn: {
    backgroundColor: theme.palette.error.dark,
    borderTopRightRadius: '32px',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(-1),
    marginLeft: theme.spacing(-2),
    padding: '22px 34px',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  notFound: {
    '& .MuiCardContent-root': {
      paddingBottom: theme.spacing(2),
    },
  },
  largeBtn: {
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
    '& .MuiButton-root': {
      minWidth: '200px',
      borderRadius: '24px',
    },
  },
}));

const CustomCard = (props) => {
  const classes = useStyles(props);
  const {
    type,
    subtitle,
    title,
    route,
    date,
    ticket,
    body,
    body2,
    body3,
    // note,
    direction,
    status,
    tripInfo,
    primaryIconBtn,
    secondaryBtn,
    backPaddingBottom,
    backMsg,
    backIcon,
    notFoundMsg,
    largeBtn
  } = props;

  const [state, setState] = React.useState({
    clickedPrimary: false
  });

  const t = useTranslation();

  const cardContent = <CardContent>
    <div className={ classes.rightContent }>
      { (status || direction) && <div className={ classes.chip }>
        { direction && <Tag name={ direction } /> }
        { status && <Status status={ status } /> }
      </div> }
      { date && <Typography variant="body2" color="textSecondary" component="p">{ date }</Typography> }
      { ticket && <Typography  variant="body2" color="textSecondary" component="p">{ ticket }</Typography> }
    </div>
    { subtitle && <Typography variant="body2" color="textSecondary" component="p">{ subtitle }</Typography> }
    { title && <Typography gutterBottom variant="h6" component="p">{ title }</Typography> }
    { route?.name && <Typography className={ classes.routeName } variant="caption" component="p">{ route.name }</Typography> }
    { route && <Route variant="small" origin={ route.origin } destination={ route.destination } /> }
    { body && <Typography variant="body2" color="textSecondary" component="p">{ body }</Typography> }
    { body2 && <Typography variant="body2" color="textSecondary" component="p">{ body2 }</Typography> }
    { body3 && <Typography variant="body2" color="textSecondary" component="p">{ body3 }</Typography> }
    {/* { note && status && (
      <div className={ classes.note }>
        <DescriptionIcon />
        <Typography variant="body2" component="p">{ note }</Typography>
      </div>
    ) } */}
    { tripInfo && (
      <div className={ classes.tripInfo }>
        <Typography variant="body1" color="textPrimary" component="p">{ tripInfo.title }</Typography>
        <Status status={ tripInfo.status } additionalClass="status" />
        <div className="passenger">
          <Typography variant="body2" color="textSecondary" component="span">{`${ t('reservations.card.tripInfo.passenger') }: `}</Typography>
          <Typography variant="body2" color="textPrimary" component="span">{ tripInfo.passenger }</Typography>
        </div>
        <div>
          <Typography variant="body2" color="textSecondary" component="span">{`${ t('reservations.card.tripInfo.driver') }: `}</Typography>
          <Typography variant="body2" color="textPrimary" component="span">{ tripInfo.driver }</Typography>
        </div>
        <Grid container spacing={ 2 }>
          { tripInfo.ticket && <Grid item xs={ 4 }>
            <Typography variant="overline" color="textSecondary" component="p">{ t('reservations.card.ticket').toUpperCase() }</Typography>
            <Typography variant="body2" color="textPrimary" component="p">{ tripInfo.ticket }</Typography>
          </Grid> }
          <Grid item xs={ 4 }>
            <Typography variant="overline" color="textSecondary" component="p">{ t('reservations.card.tripInfo.plate').toUpperCase() }</Typography>
            <Typography variant="body2" color="textPrimary" component="p">{ tripInfo.plate }</Typography>
          </Grid>
          <Grid item xs={ 4 }>
            <Typography variant="overline" color="textSecondary" component="p">{ t('reservations.card.tripInfo.id').toUpperCase() }</Typography>
            <Typography variant="body2" color="textPrimary" component="p">{ tripInfo.id }</Typography>
          </Grid>
        </Grid>
      </div>
    ) }
  </CardContent>;

  return (
    type !== 'notFound' ? (
      <Card className={ classes.card }>
        { !state.clickedPrimary ? (
          <React.Fragment>
            { secondaryBtn?.text ? <CardActionArea onClick={ () => secondaryBtn?.handler() }>{ cardContent }</CardActionArea> : cardContent }
            { (primaryIconBtn || secondaryBtn?.text || largeBtn) && <CardActions>
              { primaryIconBtn && <IconButton onClick={ () => setState({ ...state, clickedPrimary: true }) }>{ primaryIconBtn.icon }</IconButton> }
              { secondaryBtn?.text && <Button color="primary" onClick={ secondaryBtn?.handler }>{ secondaryBtn?.text }</Button> }
              { largeBtn && <div className={ classes.largeBtn }><Button variant="contained" color="primary" onClick={ largeBtn.handler }>{ largeBtn.text }</Button></div> }
            </CardActions> }
          </React.Fragment>
        ) : (
          <React.Fragment>
            <CardContent className={ classes.cardBack } style={{ paddingBottom: backPaddingBottom }}>
              <Typography variant="subtitle1"  component="p">{ backMsg }</Typography>
              { backIcon }
            </CardContent>
            <CardActions>
              <Button className={ classes.backPrimaryBtn } variant="contained" onClick={ primaryIconBtn.handler }>{ primaryIconBtn.backText }</Button>
              <Button color="primary" onClick={ () => setState({ ...state, clickedPrimary: false }) }>{ secondaryBtn.backText }</Button>
            </CardActions>
          </React.Fragment>
        ) }
      </Card>
    ) : (
      <Card className={ clsx( classes.card, classes.notFound ) }>
        <CardContent>
          <Typography variant="body1" component="h2">{ notFoundMsg }</Typography>
        </CardContent>
      </Card>
    )
  );
}

CustomCard.propTypes = {
  type: PropTypes.string,
  route: PropTypes.exact({
    name: PropTypes.string,
    origin: PropTypes.object.isRequired,
    destination: PropTypes.object.isRequired,
  }),
  subtitle: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  ticket: PropTypes.string,
  body: PropTypes.string,
  body2: PropTypes.string,
  body3: PropTypes.string,
  note: PropTypes.string,
  direction: PropTypes.string,
  status: PropTypes.string,
  tripInfo: PropTypes.exact({
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    passenger: PropTypes.string.isRequired,
    driver: PropTypes.string.isRequired,
    ticket: PropTypes.string,
    plate: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  primaryIconBtn: PropTypes.exact({
    icon: PropTypes.element.isRequired,
    handler: PropTypes.func.isRequired,
    backText: PropTypes.string.isRequired,
  }),
  secondaryBtn: PropTypes.exact({
    text: PropTypes.string,
    handler: PropTypes.func,
    backText: PropTypes.string.isRequired,
  }),
  backMsg: PropTypes.string,
  backPaddingBottom: PropTypes.string,
  backIcon: PropTypes.element,
  notFoundMsg: PropTypes.string,
  largeBtn: PropTypes.exact({
    text: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired
  }),
};

export default CustomCard;
