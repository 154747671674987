import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import LinearProgress from '@material-ui/core/LinearProgress';
import TripOriginIcon from '@material-ui/icons/TripOrigin';

import ReactMapGL, { Marker, NavigationControl, FlyToInterpolator } from 'react-map-gl';

import CustomCard from '../components/CustomCard';
import { mqttService } from '../services/mqtt/mqtt';

import { formatDate, formatTime, handleEndpointErrors } from '../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const ZOOM = 14;
const MAX_ZOOM = 17;
const MIN_ZOOM = 7;
const DEFAULT_LATITUDE = 9.93443;
const DEFAULT_LONGITUDE = -84.09822;

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: 'calc(100vh - 56px)',
    position: 'relative',
    width: '100vw',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 64px)',
    },
    '& .mapboxgl-ctrl-logo, & .mapboxgl-ctrl-bottom-right': {
      display: 'none',
    },
  },
  navigation: {
    bottom: '25px',
    position: 'fixed',
    right: '17px',
    zIndex: 3,
    '& > div': {
      borderRadius: '16px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    },
    '& .mapboxgl-ctrl-icon:focus': {
      boxShadow: 'none',
    },
  },
  marker: {
    color: theme.palette.primary.main,
  },
  placesMarker: {
    color: theme.palette.secondary.main,
    '& svg': {
      transform: 'translate(-12px, -20px)',
    },
  },
  carMarker: {
    color: theme.palette.primary.main,
    '& svg': {
      transform: 'translate(-12px, -18px)',
    },
  },
  centerMapOnCar: {
    backgroundColor: theme.palette.common.white,
    borderRadius:' 50%',
    bottom: '90px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    color: theme.palette.common.black,
    cursor: 'pointer',
    fontSize: '32px',
    padding: '6px',
    position: 'fixed',
    right: '16px',
    zIndex: 3,
  },
  reservation: {
    bottom: 0,
    left: 0,
    padding: `0 ${ theme.spacing(8) }px 0 ${ theme.spacing(2) }px`,
    position: 'fixed',
    width: '100%',
    zIndex: 2,
  }
}));

const ReservationDetail = (props) => {
  const classes = useStyles();

  const [viewport, setViewport] = React.useState({
    latitude: DEFAULT_LATITUDE,
    longitude: DEFAULT_LONGITUDE,
    zoom: ZOOM,
    maxZoom: MAX_ZOOM,
    minZoom: MIN_ZOOM,
  });

  const [showCar, setShowCar] = React.useState(false);
  const [markerCar, setMarkerCar] = React.useState({
    latitude: DEFAULT_LATITUDE,
    longitude: DEFAULT_LONGITUDE,
  });

  const onCenterMapOnMarkerHandler = () => {
    const newViewport = {
      ...viewport,
      latitude: markerCar.latitude,
      longitude: markerCar.longitude,
      zoom: ZOOM,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
    };

    setViewport( newViewport );
  }

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);
  const lastNotification = useStoreState(state => state.global.lastNotification);

  const reservation = useStoreState(state => state.reservationDetail.item);
  const reservationLoading = useStoreState(state => state.reservationDetail.loading);
  // const reservationError = useStoreState(state => state.reservationDetail.error);
  const getReservationDetail = useStoreActions(actions => actions.reservationDetail.getReservationDetail);

  const t = useTranslation();

  React.useEffect(() => {
    getReservationDetail(props.match.params.id).then(() => {
      const reservationState = store.getState().reservationDetail;
      if (!reservationState.loading && !reservationState.error) {
        // console.log(reservationState.item);

        const reservation = reservationState.item;
        const vehicle = reservation?.route?.vehicle;

        setViewport({
          ...viewport,
          latitude: reservation?.route?.origin?.latitude || DEFAULT_LATITUDE,
          longitude: reservation?.route?.origin?.longitude || DEFAULT_LONGITUDE,
          zoom: ZOOM,
          transitionDuration: 1000,
          transitionInterpolator: new FlyToInterpolator(),
        });

        setMarkerCar({
          ...markerCar,
          latitude: reservation?.route?.origin?.latitude || DEFAULT_LATITUDE,
          longitude: reservation?.route?.origin?.longitude || DEFAULT_LONGITUDE,
        });

        if ( vehicle && ['started', 'incident', 'delayed'].includes(reservation.tripStatus) ) {
          setShowCar(true);

          console.log('connecting to MQTT...');
          mqttService.connect(
            // { ident: '#', options: {} },
            { ident: vehicle.identifier, options: {} },
            message => {
              const jsonMessage = JSON.parse(message.payloadString);

              if ( jsonMessage['position.latitude'] && jsonMessage['position.longitude'] ) {
                setViewport({
                  ...viewport,
                  latitude: jsonMessage['position.latitude'],
                  longitude: jsonMessage['position.longitude'],
                  zoom: ZOOM,
                  transitionDuration: 1000,
                  transitionInterpolator: new FlyToInterpolator(),
                });

                setMarkerCar({
                  ...markerCar,
                  latitude: jsonMessage['position.latitude'],
                  longitude: jsonMessage['position.longitude'],
                });
              }

              if ( lastNotification.id === reservationState.item.id ) {
                mqttService.disconnect();
              }
            }
          );
        } else {
          console.log('%cThis reservation does not have any trip associated or reservation is not active. ', 'color: #0096ff');
        }

      } else {
        handleEndpointErrors(reservationState, props, setSnackbar, t);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);
  // }, [getReservationDetail, props, store, setSnackbar, t]);

  // React.useEffect(() => {
  //   const interval = setInterval(() => {
  //     // console.log(store.getState().global.lastNotification);
  //     setMarkerCar(markerCar => {

  //       setViewport({
  //         ...viewport,
  //         latitude: markerCar.latitude,
  //         longitude: markerCar.longitude,
  //         zoom: ZOOM,
  //         transitionDuration: 1000,
  //         transitionInterpolator: new FlyToInterpolator(),
  //       });

  //       if ( markerCar.latitude === 9.981537000000005 ) {
  //         return { latitude: markerCar.latitude, longitude: markerCar.longitude };
  //       } else {
  //         return { latitude: markerCar.latitude - 0.001, longitude: markerCar.longitude - 0.001 };
  //       }
  //     });
  //   }, 1000);
  //   return () => clearInterval( interval );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  storeMenuTitle(t('reservationDetail.pageTitle'));

  return (
    !reservationLoading ? <React.Fragment>
      <div className={ classes.mapContainer }>
        <ReactMapGL
          { ...viewport }
          width="100%"
          height="100%"
          onViewportChange={ viewport => {
            if ( viewport.zoom >= MIN_ZOOM && viewport.zoom <= MAX_ZOOM ) {
              setViewport(viewport);
            }
          } }
          mapboxApiAccessToken={ process.env.REACT_APP_MAP_TOKEN }
        >
          <div className={ classes.navigation }>
            <NavigationControl showCompass={ false } />
          </div>
          { !!reservation.id && <Marker
            className={ classes.placesMarker }
            latitude={ reservation.route.origin.latitude }
            longitude={ reservation.route.origin.longitude }
          >
            <TripOriginIcon />
          </Marker> }
          { !!reservation.id && <Marker
            className={ classes.placesMarker }
            latitude={ reservation.route.destination.latitude }
            longitude={ reservation.route.destination.longitude }
          >
            <AssistantPhotoIcon />
          </Marker> }
          { !!reservation?.id && reservation?.route?.type?.toLowerCase() === 'dtd' && <Marker
            className={ classes.placesMarker }
            latitude={ reservation.place.latitude }
            longitude={ reservation.place.longitude }
          >
            <EmojiPeopleIcon />
          </Marker> }
          { showCar ? <Marker className={ classes.carMarker } latitude={ markerCar.latitude } longitude={ markerCar.longitude }>
            <AirportShuttleIcon />
          </Marker> : null }
        </ReactMapGL>
        { showCar ? <GpsFixedIcon className={ classes.centerMapOnCar } onClick={ onCenterMapOnMarkerHandler } color="primary" /> : null }
      </div>

      { !!reservation.id ? <div className={ classes.reservation }><CustomCard
        direction={ t(`global.direction.${ reservation.route.direction }`) }
        status={ reservation.status }
        route={{
          name: reservation.route.name,
          origin: { name: reservation.route.origin.description, time: formatTime(reservation.schedule.departureTime) },
          destination: { name: reservation.route.destination.description, time: formatTime(reservation.schedule.arrivalTime) },
        }}
        date={ `${ formatDate(reservation.date, t) }` }
        note={ reservation.notes }
        ticket={ `${ t('reservations.card.ticket') }: ${ reservation.ticket }` }
        body={ `${ reservation?.route?.direction?.toLowerCase() === 'in' ? t('reservations.card.in') : t('reservations.card.out') }: ${ reservation.place.description }` }
        body2={ `${ reservation.place.county.name }, ${ reservation.place.province.name }` }
      /></div> : null }

    </React.Fragment> : <LinearProgress />
  );
}

ReservationDetail.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default withRouter(ReservationDetail);
